.markdown h1,
.markdown h2,
.markdown h3,
.markdown h4,
.markdown h5,
.markdown h6 {
    margin: 1em 0 0.4em;
}

.markdown h1 {
    font-weight: bold;
    font-size: 30px;
    line-height: 1.1em;
}

.markdown h2 {
    font-weight: bold;
    font-size: 24px;
    line-height: 1.2em;
}

.markdown h3 {
    font-weight: bold;
    font-size: 20px;
    line-height: 1.25em;
}

.markdown h4 {
    font-weight: bold;
    font-size: 18px;
    line-height: 1.28em;
}

.markdown h5 {
    font-weight: 600;
    font-size: 16.5px;
    line-height: 1.3em;
}

.markdown h6 {
    font-weight: bold;
    font-size: 14px;
    line-height: 1.33em;
}

.markdown p,
.markdown li {
    line-height: 1.8em;
}

.markdown li {
    margin: 0 0 0.5em;
}

.markdown p:last-child {
    margin-bottom: 0;
}

/* .markdown p strong:first-child {
    display: block;
} */

.markdown ul,
.markdown ol {
    padding-left: 1.2em;
}

.markdown p,
.markdown ul,
.markdown ol { 
    margin: 0 0 1em;
}

.markdown p:last-child,
.markdown ul:last-child,
.markdown ol:last-child { 
    margin: 0 0 0;
}

.markdown blockquote {
    padding: 1rem;
    margin: 1em 0;
    background: #ececec;
}

.markdown img {
    max-width: 100%;
}

.markdown hr {
    border: 0;
    border-bottom: 2px solid black;
}

.markdown table {
    /* display: block; */
    min-width: 50%;
    max-width: 100%;
    overflow: auto;
    border-spacing: 0;
    border-collapse: collapse;
    margin: 0 0 1em;
}

.markdown table tr {
    background-color: #fff;
    border-top: 1px solid #c6cbd1;
}

.markdown table th {
    font-weight: 600;
}

.markdown table td,
.markdown table th {
    padding: 6px 13px;
    border: 1px solid #dfe2e5;
}

#simple-popper .markdown a[class*="button"] {
    background-color: #fff;
    border-width: 0;
}

#simple-popper .markdown a[class*="button"]:hover {
    border-width: 0;
}